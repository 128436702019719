import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";

import Image from "./image";
import { IPhoneX } from "./iphone-x";

import { colors } from "../styles/common";

const Gallery = styled.div`
  display: grid;
  overflow: visible;
  grid-template-columns: auto auto;
  grid-gap: 1rem;
`;

const ThumbnailContainer = styled.btn`
  position: relative;
  overflow: hidden;
  min-height: 8rem;
  min-width: 4rem;
  border-radius: 0.75rem;
  object-fit: contain;
  object-position: center center;
  background-color: black;

  &:hover {
    cursor: pointer;
  }

  &:before {
    z-index: 1;
    opacity: 0;
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-radius: 0.75rem;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.5);
    transition: opacity 0.4s;
  }

  &:hover:before {
    opacity: 1;
  }

  &:after {
    z-index: 2;
    opacity: 0;
    position: absolute;
    content: "View";
    font-weight: 600;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0.5rem 1rem;
    border-radius: 0.75rem;
    border: solid 2px white;
    color: white;
    background-color: ${colors.bluish};
    transition: 0.6s ease-out;
  }

  &:hover:after {
    opacity: 1;
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
`;

const ModalContainer = styled.div`
  display: contents;
  position: absolute;
  z-index: 1001;
  top: 50vh;
  left: 50vw;
  transform: translate(-50%, -50%);
`;

const PageImageViewer = ({ pageImage, onClickOutside }) => {
  useEffect(() => {
    document.body.style.overflow = pageImage ? "hidden" : "";

    return () => {
      document.body.style.overflow = "";
    };
  }, [pageImage]);

  const handleClick = e => {
    if (e.target.id === "overlay") {
      document.body.style.overflow = "";
      onClickOutside();
    }
  };

  if (!pageImage) {
    return <></>;
  }

  return (
    <Overlay id="overlay" onClick={handleClick}>
      <ModalContainer>
        <IPhoneX>
          <Image alt="Website screenshot" src={pageImage} />
        </IPhoneX>
      </ModalContainer>
    </Overlay>
  );
};

export const ProjectGallery = ({ projects }) => {
  const [activePageImage, setActivePageImage] = useState();

  const closeModal = () => setActivePageImage(undefined);

  const handleEscapePress = e => {
    if (e.key === "Escape") {
      e.preventDefault();
      closeModal();
    }
  };

  const handleThumbnailClick = pageImage => () => setActivePageImage(pageImage);

  return (
    <>
      <PageImageViewer
        pageImage={activePageImage}
        onClickOutside={closeModal}
      />
      <Gallery>
        {projects.map(({ title, thumbnail, pageImage }) => (
          // href,
          // firstMeaningfulPaint,
          // timeToInteractive,
          // performance,
          // accessibility,
          // bestPractises,
          // seo,
          <ThumbnailContainer
            key={title}
            onKeyDown={handleEscapePress}
            onClick={handleThumbnailClick(pageImage)}
          >
            <Image alt={title} src={thumbnail} />
          </ThumbnailContainer>
        ))}
      </Gallery>
    </>
  );
};
