import React from "react";
import styled from "@emotion/styled";

import { colors } from "../styles/common";
import { FaPaperPlane } from "react-icons/fa";

const Form = styled.form`
  display: flex;
  flex-wrap: wrap;

  & input {
    margin: 2rem 2rem 0 0;
  }

  & textarea {
    resize: none;
    width: 100%;
    margin: 1rem 0;
  }

  & button {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 8rem;
    font-weight: 800;
    color: white;
    border: solid 2px white;
    background-color: ${colors.bluish};
    transition: all 0.4s;

    & svg {
      margin-right: 0.5rem;
    }
  }

  & button:hover {
    cursor: pointer;
    filter: brightness(0.75);
  }

  & input,
  textarea,
  button {
    font-size: 0.9rem;
    padding: 1rem;
    border-radius: 0.5rem;
    border: solid 2px rgba(0, 0, 0, 0.15);
  }

  & input:focus,
  textarea:focus {
    border: solid 2px ${colors.bluish};
  }

  @media only screen and (max-width: 980px) {
    flex-direction: column;
    align-items: stretch;

    & input {
      margin: 1rem 0;
    }

    & textarea {
      width: auto;
    }

    & button {
      margin: 1rem 0 0;
    }
  }
`;

export const ContactForm = () => {
  return (
    <Form
      name="contact"
      method="POST"
      data-netlify="true"
      netlify-honeypot="hpot"
    >
      <input type="hidden" name="form-name" value="contact" />
      <input type="hidden" name="hpot" />

      <input name="name" type="text" placeholder="Name" />
      <input name="email" type="email" placeholder="Email" />

      <textarea name="message" rows="10" placeholder="Message" />

      <button type="submit">
        <FaPaperPlane />
        <span>Send</span>
      </button>
    </Form>
  );
};
