import {
  FaMedal,
  FaBuilding,
  FaHardHat,
  FaLaptopCode,
  FaLifeRing,
  FaTags,
} from "react-icons/fa";
import { AusKangarooSvg } from "../svg/australian-kangaroo";

export const employmentHistory = [
  {
    from: "2021",
    to: "Now",
    title: "Kaddy",
    icon: FaBuilding,
    subEvents: [
      {
        from: "2021",
        to: "Now",
        title: "Fullstack software engineer",
        icon: FaLaptopCode,
      },
    ],
  },
  {
    from: "2014",
    to: "2021",
    title: "SafetyCulture",
    icon: FaBuilding,
    subEvents: [
      {
        from: "2018",
        to: "2021",
        title: "Fullstack software engineer",
        icon: FaLaptopCode,
      },
      {
        from: "2016",
        to: "2018",
        title: "Customer solutions engineer",
        icon: FaLaptopCode,
      },
      {
        from: "2015",
        to: "2016",
        title: "Support engineer",
        icon: FaLaptopCode,
      },
      {
        from: "2014",
        to: "2015",
        title: "Tech support",
        icon: FaLifeRing,
      },
    ],
  },
  {
    from: "2013",
    to: "2014",
    title: "Telstra",
    icon: FaBuilding,
    subEvents: [
      {
        from: "2013",
        to: "2014",
        title: "Premium technical support consultant",
        icon: FaLifeRing,
      },
    ],
  },
  {
    from: "2013",
    to: "2013",
    title: "Superstructure",
    icon: FaBuilding,
    subEvents: [
      {
        from: "2013",
        to: "2013",
        title: "Labourer",
        icon: FaHardHat,
      },
    ],
  },
  {
    from: "2008",
    to: "2014",
    title: "Australian Army",
    icon: AusKangarooSvg,
    subEvents: [
      {
        from: "2008",
        to: "2014",
        title: "Infantry Rifleman (Reservist)",
        icon: FaTags,
      },
      {
        from: "2014",
        to: "2014",
        title: "Australian Defence Medal",
        icon: FaMedal,
      },
      {
        from: "2010",
        to: "2010",
        title: "Australian Service Medal",
        icon: FaMedal,
      },
    ],
  },
  {
    from: "2007",
    to: "2010",
    title: "Flawless Tiling",
    icon: FaBuilding,
    subEvents: [
      {
        from: "2007",
        to: "2010",
        title: "Apprentice floor & wall tiler",
        icon: FaHardHat,
      },
    ],
  },
];
