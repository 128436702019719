import React from "react";
import styled from "@emotion/styled";

const Container = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  border: solid 1px rgba(0, 0, 0, 0.25);
  background-color: rgba(0, 0, 0, 0.05);

  > svg {
    margin-right: 0.5rem;
  }
`;

export const Alert = ({ icon: Icon, children }) => {
  return (
    <Container>
      {Icon && <Icon />}
      {children}
    </Container>
  );
};
