import React from "react";
import styled from "@emotion/styled";

import { colors } from "../styles/common";

import { Popover } from "./popover";

const SkillList = styled.ul`
  margin: 2rem 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  list-style-type: none;
`;

const SkillIcon = styled.li`
  display: inline-block;
  margin: 0 0.5rem;

  & svg {
    width: 2.5rem;
    height: 2.5rem;
    transition: fill 0.4s;
  }

  &:hover svg {
    cursor: pointer;
    fill: ${p => (p.color ? p.color : colors.darkgrey)};
  }
`;

export const SkillsList = ({ skills }) => {
  return (
    <SkillList>
      {skills.map(({ color, title, icon: Icon }) => (
        <SkillIcon color={color} key={title}>
          <Popover title={title}>
            <Icon />
          </Popover>
        </SkillIcon>
      ))}
    </SkillList>
  );
};
