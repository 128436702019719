import React from "react";
import { StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

const Image = props => (
  <StaticQuery
    query={graphql`
      query {
        images: allFile(filter: { extension: { in: ["jpg", "png", "jpeg"] } }) {
          edges {
            node {
              absolutePath
              name
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const image = data.images.edges.find(n => {
        return n.node.absolutePath.includes(props.src);
      });
      if (!image) {
        return null;
      }

      const fluid = image.node.childImageSharp.fluid;
      return (
        <Img
          alt={props.alt}
          fluid={fluid}
          loading={props.loading || "lazy"}
          objectFit={props.objectFit || "contain"}
          objectPosition={props.objectPosition || "center center"}
        />
      );
    }}
  />
);

export default Image;
