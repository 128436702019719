import React from "react";
import styled from "@emotion/styled";

const PhoneContainer = styled.div`
  position: relative;
  height: 868px;
  width: 428px;
  z-index: 1;

  @media only screen and (max-width: 980px) {
    transform: scale(0.8);
  }

  > *,
  > *::before,
  > *::after,
  &::before,
  &::after {
    display: block;
    box-sizing: border-box;
  }
`;

const PhoneBody = styled.div`
  height: 868px;
  width: 428px;
  padding: 28px;
  background: #222;
  border-radius: 68px;
  box-shadow: inset 0 0 2px 2px #222, inset 0 0 0 7px #222;
`;

const ScreenContent = styled.div`
  height: 100%;
  width: 375px;
  overflow: scroll;
  border-radius: 40px;
`;

const Notch = styled.div`
  position: absolute;
  top: 28px;
  height: 30px;
  left: 50%;
  width: 204px;
  margin-left: -102px;
  background: #222;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 0;
    height: 10px;
    width: 10px;
  }

  &::before {
    background: radial-gradient(
      circle at bottom right,
      transparent 0,
      transparent 75%,
      #222 75%,
      #222 100%
    );
    right: -10px;
  }

  &::after {
    background: radial-gradient(
      circle at bottom left,
      transparent 0,
      transparent 75%,
      #222 75%,
      #222 100%
    );
    left: -10px;
  }
`;

const FrontCamera = styled.div`
  &::before,
  &::after {
    content: "";
    position: absolute;
  }

  &::before {
    background: #444;
    border-radius: 50%;
    height: 14px;
    left: 50%;
    margin-left: 40px;
    top: 28px;
    width: 14px;
  }

  &::after {
    background: #444;
    border-radius: 3px;
    height: 6px;
    left: 50%;
    margin-left: -25px;
    top: 32px;
    width: 50px;
  }
`;

const VolumeButtons = styled.div`
  background: #222;
  height: 32px;
  left: -3px;
  position: absolute;
  top: 115px;
  width: 3px;

  &::before,
  &::after {
    background: #222;
    content: "";
    height: 62px;
    left: 0;
    position: absolute;
    width: 3px;
  }

  &::before {
    top: 140px;
  }

  &::after {
    top: 60px;
  }
`;

const PowerButton = styled.div`
  background: #222;
  height: 100px;
  position: absolute;
  right: -3px;
  top: 200px;
  width: 3px;
`;

export const IPhoneX = ({ children }) => {
  return (
    <PhoneContainer>
      <PhoneBody>
        <ScreenContent>{children}</ScreenContent>
      </PhoneBody>
      <Notch />
      <FrontCamera />
      <VolumeButtons />
      <PowerButton />
    </PhoneContainer>
  );
};
