import React from "react";
import styled from "@emotion/styled";

import { colors, fontColorDark } from "../styles/common";

const Container = styled.div`
  margin: 2rem 0;
  width: fit-content;

  @media only screen and (max-width: 767px) {
    margin-right: 1rem;
  }
`;

const RecursiveContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 2rem 0 2rem 2rem;
  border-left: solid 0.25rem ${fontColorDark};
  padding-left: 2rem;

  &:before {
    position: absolute;
    content: "";
    padding: 0.4rem;
    background-color: ${fontColorDark};
    top: 0;
    left: 0;
    transform: translate(calc(-50% - 0.15rem), -50%) perspective(1px);
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  }
`;

const Event = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
  padding: 1rem;
  border-radius: 0.5rem;
  background: rgba(0, 0, 0, 0.1);

  @media only screen and (max-width: 980px) {
    margin-right: 1rem;
  }

  & svg {
    fill: ${fontColorDark};
    position: absolute;
    overflow: visible;
    top: 0.5rem;
    left: 0;
    transform: translate(calc(-50% - 2rem - 2px), 0) perspective(1px);
    width: 2rem;
    height: 2rem;
    border-radius: 100%;
    border: solid 0.25rem ${fontColorDark};
    background-color: ${colors.whitish};
    padding: 0.5rem;
  }
`;

const EventDates = styled.h4`
  color: ${colors.bluish};
  margin: 0;
  white-space: nowrap;
`;

const EventTitle = styled.h3`
  color: ${colors.darkgrey};
  margin: 0;
`;

const Tree = ({ events }) => (
  <RecursiveContent>
    {events.map(({ from, to, title, icon: Icon, subEvents }) => {
      return (
        <Event key={title}>
          <Icon />
          <EventDates>{from === to ? from : `${from} → ${to}`}</EventDates>
          <EventTitle>{title}</EventTitle>
          {!!subEvents && subEvents.length && <Tree events={subEvents} />}
        </Event>
      );
    })}
  </RecursiveContent>
);

export const Timeline = ({ events }) => {
  return (
    <Container>
      <Tree events={events} />
    </Container>
  );
};
