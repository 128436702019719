import React from "react";

export class AusKangarooSvg extends React.PureComponent {
  render() {
    return (
      <svg
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 938.339 938.339"
        style={{
          enableBackground: "new 0 0 938.339 938.339",
          ...this.props.style,
        }}
        xmlSpace="preserve"
      >
        <g>
          <path
            d="M130.668,173.814c0,0,31.5,112.5,99,174l-39.3,36.6c-2.7,2.5-3.8,6.399-2.7,9.899c4.6,14.601,21.1,51,71.1,51
     c46.2,0,59.5-34.3,63.2-52.199c1.199-5.7-2.9-11.101-8.601-11.601l-19.899-1.7c-4.9-0.399-9.301,2.9-10.301,7.7
     c-2.3,11.601-10,30.2-36.399,22.2c-37.3-11.3,91.3-80,91.3-80s34.3,22.8,81.7,39.3c0.8-2.2,1.6-4.3,2.399-6.6
     c8.2-22.2,20.801-43.7,36.801-61.2c18.1-20,40.399-33,66.199-40c-5,1.4-10.899,6.5-15.1,9.5c-6.2,4.4-11.8,9.6-16.9,15.2
     c-10.3,11.3-18.3,24.6-24.1,38.7c-10,24.6-15.4,54.8-12.9,81.4c4.101,44.101,19.5,132.7,77.5,193.601c80,84,77,103,44,204
     c-23.5,71.8-6,112.8,6.101,130.5c3.899,5.699,12.2,5.6,16-0.101l12.5-18.8c1.399-2.2,1.899-4.8,1.399-7.3
     c-3.199-15.7-9.199-80.9,72.801-202.2c2.399-3.5,2.199-8.2-0.5-11.5c-17.301-21.2-77.5-100.4-84.4-188.5c-8-103,84-96,97-186
     c0,0,88.8,68.7,81.3,253.2c-7.399,181,8.4,284.1,118.101,329c3.8,1.5,8.1,0.5,10.8-2.5c3.8-4.2,3.1-10.8-1.4-14.2
     c-27-20-102.9-98.9-55.5-333.3c57-282-214.5-433.5-352.5-433.5s-258,112.5-286.5,0c0,0,63.8-26.4,67.4-108.4
     c0.199-5.7-4.4-10.3-10.101-10c-25.1,1-89.4,10-109.8,73.5l-62.1,11c-3.4,0.6-6.2,2.9-7.4,6.1l-10.2,27.2c-0.9,2.3-2.6,4.2-4.8,5.3
     l-43.3,21.1c-5.3,2.6-7.1,9.2-3.8,14C37.769,171.614,62.968,191.714,130.668,173.814z"
          />
        </g>
      </svg>
    );
  }
}
