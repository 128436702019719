export const projects = [
  {
    title: "Sing Smart Studios",
    thumbnail: "/assets/images/singsmarthero.png",
    pageImage: "/assets/images/singsmartpage.png",
    href: "https://singsmartstudios.com",
    firstMeaningfulPaint: 0.8,
    timeToInteractive: 3.7,
    performance: 96,
    accessibility: 100,
    bestPractises: 100,
    seo: 100,
  },
  {
    title: "Six Strings Townsville",
    thumbnail: "/assets/images/sixstringshero.png",
    pageImage: "/assets/images/sixstringspage.png",
    href: "https://sixstringstownsville.com.au",
    firstMeaningfulPaint: "TBD",
    timeToInteractive: "TBD",
    performance: "TBD",
    accessibility: "TBD",
    bestPractises: "TBD",
    seo: "TBD",
  },
  // {
  //   title: "Unsplash Search",
  //   thumbnail: "/assets/images/unsplashthumb.png",
  //   pageImage: "/assets/images/unsplashpage.png",
  //   href: "https://image-searcher-task.vercel.app",
  //   firstMeaningfulPaint: "TBD",
  //   timeToInteractive: "TBD",
  //   performance: "TBD",
  //   accessibility: "TBD",
  //   bestPractises: "TBD",
  //   seo: "TBD",
  // },
];
