import { colors } from "../styles/common";
import {
  DiReact,
  DiEmber,
  DiHtml5,
  DiCss3Full,
  DiGit,
  DiCoffeescript,
  DiPython,
  DiGo,
  DiJava,
  DiDotnet,
  DiPostgresql,
  DiMysql,
  DiMongodb,
} from "react-icons/di";
import { FaAws, FaNode, FaJsSquare, FaVuejs } from "react-icons/fa";
import {
  SiTypescript,
  SiNextdotjs,
  SiRedux,
  SiSwift,
  SiXstate,
} from "react-icons/si";
import { GatsbySvg } from "../svg/gatsby";

export const currentSkills = [
  {
    title: "Typescript",
    color: colors.typescriptBlue,
    icon: SiTypescript,
  },
  {
    title: "ReactJs",
    color: colors.reactBlue,
    icon: DiReact,
  },
  {
    title: "NextJs",
    color: colors.darkgrey,
    icon: SiNextdotjs,
  },
  {
    title: "Redux\nZustand",
    color: colors.reduxPurple,
    icon: SiRedux,
  },
  {
    title: "NodeJs",
    color: colors.nodeGreen,
    icon: FaNode,
  },
  {
    title: "HTML5",
    color: colors.htmlOrange,
    icon: DiHtml5,
  },
  {
    title: "CSS3\nTailwind\nStyled-Components\nChakraUI",
    color: colors.css3Blue,
    icon: DiCss3Full,
  },
  {
    title: "Git",
    color: colors.gitOrange,
    icon: DiGit,
  },
  {
    title: "PostgreSQL",
    color: colors.postgresBlue,
    icon: DiPostgresql,
  },
];

export const previousSkills = [
  {
    title: "VueJs / Nuxt",
    color: colors.vueGreen,
    icon: FaVuejs,
  },
  {
    title: "GatsbyJs",
    color: colors.gatsbyPurple,
    icon: GatsbySvg,
  },
  {
    title: "XState",
    color: colors.xstateBlack,
    icon: SiXstate,
  },
  {
    title: "EmberJs",
    color: colors.emberRed,
    icon: DiEmber,
  },
  {
    title: "Javascript",
    color: colors.javascriptYellow,
    icon: FaJsSquare,
  },
  {
    title: "Swift",
    color: colors.swiftOrange,
    icon: SiSwift,
  },
  {
    title: "Golang",
    color: colors.gopherBlue,
    icon: DiGo,
  },
  {
    title: "Python",
    color: colors.pythonBlue,
    icon: DiPython,
  },
  {
    title: "Coffeescript",
    color: colors.coffeescript,
    icon: DiCoffeescript,
  },
  {
    title: "C#",
    color: colors.dotnetBlue,
    icon: DiDotnet,
  },
  {
    title: "Java",
    color: colors.javaRed,
    icon: DiJava,
  },
  {
    title: "MySQL / MSSQL",
    color: colors.mysqlBlue,
    icon: DiMysql,
  },
  {
    title: "MongoDB / Couchbase",
    color: colors.mongodbGreen,
    icon: DiMongodb,
  },
  {
    title: "Amazon Web Services",
    color: colors.awsYellow,
    icon: FaAws,
  },
];
