import styled from "@emotion/styled";

export const Divider = styled.hr`
  margin: 4rem 0;
  opacity: 0.1;

  @media only screen and (max-width: 980px) {
    margin: 2rem 0;
    opacity: 0.2;
  }
`;
