import React from "react";

import styled from "@emotion/styled";

import { BiInfoCircle } from "react-icons/bi";
import Layout from "../containers/layout";
import SEO from "../components/seo";

import { currentSkills, previousSkills } from "../constants/skills";
import { projects } from "../constants/projects";
import { employmentHistory } from "../constants/employment";

import { Divider } from "../components/misc";
import { SkillsList } from "../components/skills-list";
import { ProjectGallery } from "../components/project-gallery";
import { Timeline } from "../components/timeline";
import { ContactForm } from "../components/contact-form";
import { Testimonial } from "../components/testimonial";
import { Alert } from "../components/alert";
import { colors, fontWeightBold, fontWeightMed } from "../styles/common";

const CodeBlock = styled.span`
  background: ${colors.xlightBlue};
  padding: 0.075rem;
  font-size: 0.85rem;
  font-weight: ${fontWeightBold};
`;

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <h1>Welcome to my website</h1>
    <article>
      <p>
        I'm an information technology professional and currently work as a
        software engineer. Dream job.
      </p>
    </article>
    <article>
      <p>
        This site has been put together using <CodeBlock>React</CodeBlock>,{" "}
        <CodeBlock>Gatsby</CodeBlock>, <CodeBlock>Emotion</CodeBlock>,{" "}
        <CodeBlock>Netlify</CodeBlock> and is fully hand-crafted. I enjoy
        finding creative and flexible ways to layout, style and animate, so you
        won't find any Bootstrap here.
      </p>
    </article>
    {/* TODO: website performance metrics (lighthouse audit) */}
    <Divider />
    <h2>My attitude toward work and life</h2>
    <article>
      <Testimonial>
        <p>
          Progress is not achieved by luck or accident but by working on
          yourself daily.
        </p>
        <span>Epictetus</span>
      </Testimonial>
    </article>
    <Divider />
    <h2>My usual tools of the trade</h2>
    <article style={{ marginBottom: "1.5rem" }}>
      While I have experience programming outside of the web development domain,
      I prefer to work with Typescript and the Javascript ecosystem. Below are
      the languages, frameworks and tools I'm most experienced with.
    </article>
    <Alert icon={BiInfoCircle}>Mouse over or tap on any icon for more</Alert>
    <SkillsList skills={currentSkills} />
    <h3>And others I've used before</h3>
    <SkillsList skills={previousSkills} />
    <Divider />
    <h2>Past projects</h2>
    <article style={{ marginBottom: "1.5rem" }}>
      Freelance work and other projects
    </article>
    <Alert icon={BiInfoCircle}>Click or tap to preview</Alert>
    <div style={{ height: "1.5rem" }} /> {/* todo: remove hack */}
    <ProjectGallery projects={projects} />
    <Divider />
    {/* <h2>See some code, warts and all</h2>
    <article style={{ marginBottom: "1.5rem" }}>
      Being a mere mortal with limited time, I don't keep these constantly
      updated. They're each a product of the time.
    </article>
    <ul>
      <li>
        <a href="" rel="noopener">
          This site
        </a>
      </li>
      <li>
        <a href="" rel="noopener">
          Unsplash search
        </a>
      </li>
    </ul>
    <Divider /> */}
    <h2>Employment history</h2>
    <article>
      Despite being interested in programming from a young age, my working life
      began in the construction industry and military, before I began venturing
      professionally back into telecommunications, IT and software.
    </article>
    <Timeline events={employmentHistory} />
    <Divider />
    <h2>Reach out to me</h2>
    <article>
      I'm always open to learning about exciting new opportunities.
    </article>
    <ContactForm />
  </Layout>
);

export default IndexPage;
