import React from "react";
import styled from "@emotion/styled";

import { colors } from "../styles/common";

const placementStyles = {
  bubble: {
    top: `
      top: 0;
      left: 50%;
      transform: translate(-50%, calc(-100% - 0.5rem));
    `,

    bottom: `
      bottom: 0;
      left: 50%;
      transform: translate(-50%, calc(100% + 0.5rem));
    `,

    left: `
      top: 50%;
      left: 0;
      transform: translate(calc(-100% - 0.5rem), -50%);
    `,

    right: `
      top: 50%;
      right: 0;
      transform: translate(calc(100% + 0.5rem), -50%);
    `,
  },
  indicator: {
    top: `
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 50%) rotate(45deg);
    `,

    bottom: `
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
    `,

    left: `
      top: 50%;
      right: 0;
      transform: translate(50%, -50%) rotate(45deg);
    `,

    right: `
      top: 50%;
      left: 0;
      transform: translate(-50%, -50%) rotate(45deg);
    `,
  },
};

const PopoverWidget = styled.pre`
  pointer-events: none;
  opacity: 0;
  z-index: 2;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 0.8rem;
  color: ${colors.whitish};
  background-color: ${p =>
    p.backgroundColor ? p.backgroundColor : colors.darkgrey};
  border-radius: 0.5rem;
  min-width: 6rem;
  padding: 0.5rem 0.75rem;
  ${p =>
    p.placement
      ? placementStyles.bubble[p.placement]
      : placementStyles.bubble["top"]};
  transition: all 0.4s;

  & h4 {
    font-weight: lighter;
  }

  &:after {
    pointer-events: initial;
    content: "";
    z-index: 1;
    position: absolute;
    padding: 0.25rem;
    ${p =>
      p.placement
        ? placementStyles.indicator[p.placement]
        : placementStyles.indicator["top"]}
    background-color: ${p =>
      p.backgroundColor ? p.backgroundColor : colors.darkgrey};
  }
`;

const TargetWrapper = styled.div`
  position: relative;
  &:hover ${PopoverWidget} {
    opacity: 1;
  }
`;

export const Popover = ({ backgroundColor, placement, title, children }) => {
  return (
    <TargetWrapper>
      <>
        {children}
        <PopoverWidget backgroundColor={backgroundColor} placement={placement}>
          <h4>{title}</h4>
        </PopoverWidget>
      </>
    </TargetWrapper>
  );
};
